import React from "react";
import styled, { keyframes } from "styled-components";

const rotate = keyframes`
  100% { transform: rotate(360deg)};
`;

const StyledSpinner = styled.div`
  animation: ${rotate} 2s ease infinite;
  border: 1px solid #eee;
  border-top: 1px solid black;
  border-radius: 50%;
  height: 100px;
  width: 100px;
`;

export const Spinner = () => <StyledSpinner />;
