import React from "react";
import styled from "styled-components";
import { ReactComponent as logo } from "../common/assets/images/logo.svg";

const A = styled(logo)`
  height: auto;
  width: 50px;
`;

const ARSEWrapper = styled.span`
  display: flex;
  font-size: 32px;
  position: relative;
  > span {
    left: 42px;
    position: absolute;
    top: 8px;
  }
`;

const Container = styled.div`
  display: flex;
  justify-content: center;
  overflow-y: scroll;
  padding-bottom: 2em;
`;

const Content = styled.div`
  border: 1px solid #ccc;
  height: fit-content;
  margin-top: 2em;
  max-width: 600px;
  padding: calc(1em + 5px);
  h1,
  h3 {
    margin: 0;
  }
  p {
    margin-top: 0;
  }
`;

const LinkContainer = styled.div`
  display: flex;
  flex-direction: column;
  > a {
    text-decoration: none;
    width: fit-content;
    &:hover {
      text-decoration: underline;
    }
  }
`;

const Location = styled.span`
  color: #aaa;
`;

const otherResources = [
  {
    title: "Alternative Art Guide",
    url: "https://alternativeartguide.com/",
    location: "Earth",
  },
  {
    title: "Art Blog Map",
    url: "https://www.theartblog.org/artblog-map/",
    location: "Philidelphia",
  },
  {
    title: "Art Listing",
    url: "https://artlisting.org/",
    location: "Earth",
  },
  {
    title: "Burnaway DIY Index",
    url: "https://burnaway.org/maps/diy/",
    location: "Southern United States",
  },
  {
    title: "Common Field",
    url: "https://www.commonfield.org/",
  },
  {
    title: "Gossip's Web",
    url: "https://gossipsweb.net/",
    location: "Earth",
  },
  {
    title: "Grapefruit Juice Artist Resource Guide",
    url: "https://www.grapefruitjuiceartists.org/galleries-museums",
    location: "Portland",
  },
  {
    title: "Impractical Spaces",
    url: "https://www.impracticalspaces.org/",
    location: "United States",
  },
  {
    title: "Independant Space Index",
    url: "https://www.independentspaceindex.at/",
    location: "Vienna",
  },
];

export const About = () => {
  return (
    <Container>
      <Content>
        <ARSEWrapper>
          <A />
          <span>RSE</span>
        </ARSEWrapper>
        <p>(Artist Run Space Encyclopedia)</p>
        <p>
          is an archival resource with the intent to preserve the history of
          artist run spaces and alternative forms of showing and exhibiting
          ideas. Created and maintained by{" "}
          <a href="https://goodenoughfor.me" rel="noreferrer" target="_blank">
            Good Enough
          </a>
          .
        </p>
        <LinkContainer>
          <h3>Other resources:</h3>
          {otherResources.map(({ title, url, location }) => (
            <a href={url} key={title} rel="noreferrer" target="_blank">
              {title} {location && "-"}{" "}
              {location && <Location>{location}</Location>}
            </a>
          ))}
        </LinkContainer>
      </Content>
    </Container>
  );
};
