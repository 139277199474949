import PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 1em;
  label {
    color: ${({ disabled }) => (disabled ? "#aaa" : "default")};
  }
  select {
    background: rgba(0, 0, 0, 0);
    border: 1px solid #aaa;
    border-radius: 0;
    cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};
    min-height: 24px;
    padding: 0.125em 0.25em 0;
    &:focus {
      outline: none;
    }
  }
  @media (max-width: 510px) {
    align-items: center;
    flex-direction: column;
    margin-bottom: 5px;
    width: 100%;
    select {
      width: 100%;
    }
  }
`;

export const Select = ({
  defaultValue,
  defaultValueDisabled,
  disabled,
  header,
  label,
  onChange,
  options,
  required,
  title,
}) => {
  return (
    <Container disabled={disabled}>
      {label && (
        <label>
          {label}
          {required && " *"}
        </label>
      )}
      <select
        onChange={onChange}
        title={title}
        defaultValue={defaultValue}
        disabled={disabled}
      >
        {header && (
          <option value={header} disabled>
            {header}
          </option>
        )}
        {defaultValue && (
          <option value={defaultValue} disabled={defaultValueDisabled}>
            {defaultValue}
          </option>
        )}
        {options.map((el) => (
          <option value={el} key={el}>
            {el}
          </option>
        ))}
      </select>
    </Container>
  );
};

Select.propTypes = {
  defaultValue: PropTypes.string,
  defaultValueDisabled: PropTypes.bool,
  disabled: PropTypes.bool,
  header: PropTypes.string,
  label: PropTypes.string,
  onChange: PropTypes.func,
  options: PropTypes.array,
  required: PropTypes.bool,
  title: PropTypes.string,
};
