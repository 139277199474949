import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { ReactComponent as logo } from "../../common/assets/images/logo.svg";

const Container = styled.div`
  align-items: center;
  background: #f8f4eb;
  border-right: 1px solid #ccc;
  display: flex;
  flex-direction: column;
  height: inherit;
  position: absolute;
  width: 130px;
  transition: width 1s;
  z-index: 1;
  @media (max-width: 700px) {
    width: ${({ navOpen }) => (navOpen ? "50%" : "50px")};
  }
`;

const Logo = styled(logo)`
  height: auto;
  margin: 1em 0;
  width: 50px;
  @media (max-width: 700px) {
    cursor: pointer;
  }
`;

const Items = styled.div`
  display: flex;
  flex-direction: column;
  opacity: ${({ navOpen }) => (navOpen ? 1 : 0)};
  transition: opacity 1s ${({ navOpen }) => (navOpen ? "0.5s" : "0")};
  visibility: ${({ navOpen }) => (navOpen ? "visible" : "hidden")};
`;

export const SideNav = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 700);
  const [navOpen, toggleNav] = useState(!isMobile);
  let onClick = () => {
    if (!isMobile) return;
    toggleNav(!navOpen);
  };
  useEffect(() => {
    window.addEventListener("resize", () => {
      setIsMobile(window.innerWidth < 700);
    });
  }, []);
  return (
    <Container navOpen={navOpen}>
      <Logo onClick={onClick} />
      <Items navOpen={navOpen}>
        <Link to="/encyclopedia">Encyclopedia</Link>
        <Link to="/about">About</Link>
      </Items>
    </Container>
  );
};
