import React from "react";
import { Outlet } from "react-router-dom";
import styled from "styled-components";
import { Header } from "../../components";
import { SpacesProvider } from "../../context";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: inherit;
`;

const headerItems = [
  {
    title: "Encyclopedia",
    url: "/",
  },
  // {
  //   title: "Submit",
  //   url: "/submit",
  // },
  {
    title: "About",
    url: "/about",
  },
];

export const Client = () => {
  return (
    <Container>
      <SpacesProvider>
        <Header items={headerItems} />
        <Outlet />
      </SpacesProvider>
    </Container>
  );
};
