import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import { convert } from "../../../utils";

const Line = styled.div`
  display: flex;
  width: 100%;
  a {
    text-decoration-color: ${({ underlineColor }) => underlineColor};
  }
  label {
    display: inline-block;
    margin-right: 1em;
    text-align: right;
    width: 70px;
  }
  span {
    flex: 1;
  }
`;

const Location = styled.span`
  display: flex;
  flex-direction: column;
`;

const StatusMarker = styled.span`
  background: ${({ status }) => (status === "Active" ? "green" : "red")};
  border-radius: 9999px;
  display: inline-block;
  height: 8px;
  margin-bottom: 1px;
  opacity: 0.25;
  width: 8px;
`;

export const SpaceDetails = ({ space }) => {
  const underlineColor = convert.stringToColor(space.name);
  return (
    <>
      <Line>
        <label>name</label>
        <span>{space.name}</span>
      </Line>
      <Line>
        <label>status</label>
        <span>
          {space.status} <StatusMarker status={space.status} />
        </span>
      </Line>
      <Line>
        <label>founded</label>
        <span>{space.yearFounded}</span>
      </Line>
      {space.directors && (
        <Line>
          <label>directors</label>
          <span>{space.directors}</span>
        </Line>
      )}
      <Line>
        <label>location</label>
        <Location>
          {space.street && <span>{space.street}</span>}
          {space.city && space.state && (
            <span>
              {space.city}, {space.state}
            </span>
          )}
          {space.city && !space.state && <span>{space.city}</span>}
          {!space.city && space.state && <span>{space.state}</span>}
          <span>{space.country}</span>
        </Location>
      </Line>
      {space.email && (
        <Line>
          <label>email</label>
          <span>{space.email}</span>
        </Line>
      )}
      {space.website && (
        <Line underlineColor={underlineColor}>
          <label>website</label>
          <span>
            <a href={space.website} rel="noreferrer" target="_blank">
              {space.website}
            </a>
          </span>
        </Line>
      )}
      {space.instagram && (
        <Line underlineColor={underlineColor}>
          <label>instagram</label>
          <span>
            <a
              href={`https://www.instagram.com/${space.instagram}`}
              rel="noreferrer"
              target="_blank"
            >
              @{space.instagram}
            </a>
          </span>
        </Line>
      )}
    </>
  );
};

SpaceDetails.propTypes = {
  space: PropTypes.object,
};
