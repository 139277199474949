import React, { createContext, useContext, useReducer } from "react";
import PropTypes from "prop-types";

const initialSpaces = [];
const SpacesContext = createContext(null);
const SpacesDispatchContext = createContext(null);

export function useSpaces() {
  return useContext(SpacesContext);
}

export function useSpacesDispatch() {
  return useContext(SpacesDispatchContext);
}

function spacesReducer(spaces, newSpaces) {
  return [...spaces, ...newSpaces];
}

export function SpacesProvider({ children }) {
  const [spaces, dispatch] = useReducer(spacesReducer, initialSpaces);
  return (
    <SpacesContext.Provider value={spaces}>
      <SpacesDispatchContext.Provider value={dispatch}>
        {children}
      </SpacesDispatchContext.Provider>
    </SpacesContext.Provider>
  );
}

SpacesProvider.propTypes = {
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
};
