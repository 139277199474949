import PropTypes from "prop-types";
import React, { useState } from "react";
import styled from "styled-components";

const Container = styled.div`
  align-items: center;
  display: flex;
  label {
    line-height: 1em;
  }
`;

const Box = styled.div`
  background: ${({ active }) => (active ? "#716c8c" : "white")};
  border: ${({ active }) => (active ? "none" : "1px solid #aaa")};
  border-radius: 2px;
  cursor: pointer;
  height: 14px;
  margin-right: 5px;
  width: 14px;
`;

export const Checkbox = ({ label, onClick, title }) => {
  const [active, setActive] = useState(false);
  const onClickHandler = (e) => {
    e.target.value = !active;
    setActive(e.target.value);
    if (onClick) onClick(e);
  };
  return (
    <Container>
      <Box active={active} onClick={onClickHandler} title={title} />
      <label>{label}</label>
    </Container>
  );
};

Checkbox.propTypes = {
  label: PropTypes.string,
  onClick: PropTypes.func,
  title: PropTypes.string,
};
