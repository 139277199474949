import React, { useEffect } from "react";
import { Outlet } from "react-router-dom";
import styled from "styled-components";
import { SideNav } from "../../components";

const Container = styled.div`
  height: 100vh;
`;

const navItems = [
  {
    title: "Submissions",
    url: "/submissions",
  },
  {
    title: "Add",
    url: "/add",
  },
  {
    title: "Logout",
    url: "/logout",
  },
];

export const Admin = () => {
  // let history = useHistory();

  useEffect(() => {
    if (!window.localStorage.getItem("auth")) {
      // check it
    }
  }, []);

  return (
    <Container>
      <SideNav items={navItems} />
      <Outlet />
    </Container>
  );
};
