import React from "react";
import { Link, useRouteError } from "react-router-dom";
import styled from "styled-components";

const Container = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100vh;
  width: 100vw;
`;

export const Error = () => {
  const error = useRouteError();
  console.error(error);
  return (
    <Container>
      <div>{error.status}</div>
      <div>
        <i>{error.statusText || error.message}</i>
      </div>
      <Link to="/">Home</Link>
    </Container>
  );
};
