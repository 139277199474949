import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

const Container = styled.div`
  button {
    background: white;
    border: solid 1px #ccc;
    border-left: none;
    cursor: pointer;
    padding: 0.25em 1em;
    &:focus {
      outline: none;
    }
    &:hover {
      background: black;
      color: white;
    }
    &:active {
      background: #ccc;
      color: white;
    }
  }
  input {
    border: 1px solid #ccc;
    padding: 0.25em;
    &:focus {
      outline: none;
    }
  }
  .error {
    color: red;
  }
`;

export const Login = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  let [error, setError] = useState(false);
  let navigate = useNavigate();

  useEffect(() => {
    if (window.localStorage.auth) {
      navigate("/admin");
    }
  }, []);

  const onChangeHandler = ({ target: { value } }) => {
    setUsername(value);
    setPassword(value);
  };

  const onSubmitHandler = (e) => {
    if (e.type === "click" || e.key === "Enter") {
      fetch(
        "https://0wstubkd2e.execute-api.us-east-1.amazonaws.com/api/admin",
        {
          method: "POST",
          body: JSON.stringify(this.state),
          headers: { "Content-Type": "application/json" },
        },
      )
        .then((res) => res.json())
        .then((res) => {
          if (res.statusCode === 200) {
            window.localStorage.setItem("auth", res.auth);
          } else {
            setError(true);
            setPassword("");
          }
        });
    }
  };

  return (
    <Container>
      <input
        onChange={onChangeHandler}
        onKeyPress={onSubmitHandler}
        value={username}
        type="text"
      />
      <input
        onChange={onChangeHandler}
        onKeyPress={onSubmitHandler}
        value={password}
        type="password"
      />
      <button onClick={onSubmitHandler}>Log In</button>
      {error ? <div className="error">Unauthorized</div> : null}
    </Container>
  );
};
