import React from "react";
import { PropTypes } from "prop-types";
import styled from "styled-components";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 1em;
  label {
    margin-bottom: 2.6px;
    > span {
      color: #777;
    }
  }
`;

const Input = styled.input`
  background: transparent;
  border: none;
  border-bottom: 1px solid #aaa;
  &:focus {
    outline: none;
  }
  // * Chrome, Firefox, Opera, Safari 10.1+
  ::placeholder {
    color: #777;
    opacity: 1; // * Firefox
  }
  // * Internet Explorer 10-11
  :-ms-input-placeholder {
    color: #777;
  }
  // * Microsoft Edge
  ::-ms-input-placeholder {
    color: #777;
  }
  // * Safari
  &::-webkit-input-placeholder {
    line-height: normal !important;
  }
  @media (max-width: 510px) {
    margin-bottom: 5px;
    width: 100%;
  }
`;

export const Text = ({
  disabled,
  hint,
  label,
  onChange,
  placeholder,
  required,
  styleType,
  title,
}) => {
  return (
    <Container disabled={disabled}>
      <label>
        {label}
        {required && " *"}
        {hint && <span> ({hint})</span>}
      </label>
      <Input
        disabled={disabled}
        onChange={onChange}
        placeholder={placeholder}
        title={title}
        styleType={styleType}
      />
    </Container>
  );
};

Text.propTypes = {
  disabled: PropTypes.bool,
  hint: PropTypes.string,
  label: PropTypes.string,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  styleType: PropTypes.any,
  title: PropTypes.string,
};
