import React, { useState } from "react";
import styled from "styled-components";
import { render } from "../../utils";

const Column = styled.section`
  display: inline-flex;
  flex-direction: column;
  padding: 0 5px;
  width: 50%;
`;

const Container = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  width: 100%;
`;

const Form = styled.form`
  border: 1px solid #ccc;
  margin-top: 2em;
  padding: 1em;
  width: 500px;
`;

const Header = styled.div`
  margin-bottom: 1em;
  margin-left: 5px;
`;

const Row = styled.div`
  margin-bottom: 1em;
`;

const SubmitButton = styled.button`
  background: #00000000;
  border: 1px solid #ccc;
  border-radius: 1em;
  color: #000;
  cursor: pointer;
  display: block;
  padding: 0.5em;
  transition:
    background 0.5s,
    color 0.5s;
  width: 100%;
  &:focus {
    outline: none;
  }
  &:hover {
    background: #000;
    color: #eee;
  }
`;

const Tab = styled.span`
  color: ${({ isActive }) => (isActive ? "#000" : "#ccc")};
  cursor: pointer;
  margin-right: 1em;
`;

export const Submit = () => {
  const [activeTab, setActiveTab] = useState("New");

  const [data, setData] = useState({
    name: "",
    status: null,
    yearFounded: null,
    type: null,
    directors: "",
    street: "",
    city: "",
    state: "",
    country: null,
    website: "",
    instagram: "",
    email: "",
  });

  const spaces = [];

  const nameInput =
    activeTab === "New"
      ? {
          label: "Name",
          required: true,
          type: "text",
        }
      : {
          defaultValue: "-",
          defaultValueDisabled: true,
          label: "Name",
          options: spaces,
          required: true,
          type: "select",
        };

  const inputs = {
    column1: [
      nameInput,
      {
        defaultValue: "-",
        defaultValueDisabled: true,
        label: "Status",
        type: "select",
        options: ["Active", "Inactive"],
        required: true,
      },
      {
        disabled: data.type === "Digital",
        hint: data.type === "Nomadic" && "location project is based",
        label: "Street",
        type: "text",
      },
      {
        disabled: data.type === "Digital",
        label: "State/Province",
        title: "state",
        type: "text",
      },
      {
        hint: "separate by commas",
        label: "Directors",
        type: "text",
      },
      {
        label: "Website",
        type: "text",
      },
    ],
    column2: [
      {
        defaultValue: "-",
        label: "Year Founded",
        required: true,
        title: "yearFounded",
        type: "text",
      },
      {
        defaultValue: "-",
        defaultValueDisabled: true,
        label: "Type",
        options: ["Physical", "Nomadic", "Digital"],
        required: true,
        type: "select",
      },
      {
        disabled: data.type === "Digital",
        label: "City",
        type: "text",
      },
      {
        disabled: data.type === "Digital",
        label: "Country",
        type: "text",
      },
      {
        label: "Email",
        type: "text",
      },
      {
        label: "Instagram",
        type: "text",
      },
    ],
  };

  const onChangeHandler = ({ target: { title, value } }) => {
    setData({
      ...data,
      [title]: value,
    });
  };

  return (
    <Container>
      <Form>
        <Header>
          {["New", "Edit"].map((tab, i) => (
            <Tab
              isActive={activeTab === tab}
              key={`tab-${i}`}
              onClick={() => setActiveTab(tab)}
            >
              {tab}
            </Tab>
          ))}
        </Header>
        <Row>
          <Column>
            {inputs.column1.map((input) =>
              render.input(input, onChangeHandler),
            )}
          </Column>
          <Column>
            {inputs.column2.map((input) =>
              render.input(input, onChangeHandler),
            )}
          </Column>
        </Row>
        <SubmitButton>Submit</SubmitButton>
      </Form>
    </Container>
  );
};
