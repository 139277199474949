import React from "react";
import { PropTypes } from "prop-types";
import styled, { keyframes } from "styled-components";

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const Container = styled.div`
  animation: ${fadeIn} 1s;
  height: 100%;
  overflow-y: scroll;
  padding: 2em;
  width: 100%;
`;

const Section = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 3em;
  opacity: ${({ isSorting }) => (isSorting ? 0 : 1)};
  position: relative;
  transition: opacity 0.5s;
  width: 100%;
  > div:nth-child(2) {
    align-items: flex-end;
    display: flex;
    flex-direction: column;
    > span {
      cursor: pointer;
    }
  }
  &:last-child {
    padding-bottom: calc(100vh - 58.5px - 4em - 48px);
  }
`;

const Tab = styled.div`
  font-size: 3em;
  line-height: 1em;
  position: -webkit-sticky; // * Safari
  position: sticky;
  top: 0;
  transform: translateX(${({ isSorting }) => (isSorting ? "-2rem" : 0)});
  transition: transform 0.5s;
`;

const TabMask = styled.div`
  background-image: linear-gradient(
    #f8f4eb,
    transparent 30px,
    transparent 70px,
    #f8f4eb 90px,
    #f8f4eb
  );
  height: calc(100% - 58.5px);
  left: 0;
  margin-top: 58.5px;
  position: absolute;
  top: 0;
  width: 100px;
  z-index: 1;
`;

export const OrderedList = ({ items, onClick, sortBy }) => {
  items.sort((a, b) => {
    if (a[sortBy].toUpperCase() > b[sortBy].toUpperCase()) return 1;
    if (a[sortBy].toUpperCase() < b[sortBy].toUpperCase()) return -1;
    return 0;
  });
  let currentLetter;
  let letterIndex = -1;
  const sections = items.reduce((arr, item) => {
    if (currentLetter !== item[sortBy][0].toUpperCase()) {
      currentLetter = item[sortBy][0].toUpperCase();
      letterIndex++;
      arr.push({ [item[sortBy][0].toUpperCase()]: [] });
    }
    arr[letterIndex][currentLetter].push(item);
    return arr;
  }, []);
  return (
    <Container>
      <TabMask />
      {sections.map((section, i) => (
        <Section key={`section-${i}`}>
          <Tab>{Object.keys(section)[0]}</Tab>
          <div className={`section-${Object.keys(section)[0]}`}>
            {section[Object.keys(section)].map((item, i) => (
              <span key={`item-${i}`} onClick={() => onClick(item)}>
                {item.name}
              </span>
            ))}
          </div>
        </Section>
      ))}
    </Container>
  );
};

OrderedList.propTypes = {
  items: PropTypes.array,
  onClick: PropTypes.func,
  sortBy: PropTypes.string,
};
