import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { OrderedList, Spinner } from "../../components";
import { SpaceDetails } from "./components";
import { useSpaces, useSpacesDispatch } from "../../context";
import { convert } from "../../utils";
// import { orderByKey, sortArr } from "../../utils";

const Container = styled.main`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const Content = styled.div`
  display: flex;
  height: calc(100vh - 58.5px);
`;

const DetailContainer = styled.div`
  padding: 1em;
  width: 600px;
`;

const ListContainer = styled.div`
  align-items: center;
  border-right: 1px #ccc solid;
  display: flex;
  height: 100%;
  justify-content: center;
  width: 100%;
`;

const SpaceColor = styled.div`
  background: ${({ color }) => color};
  height: 200px;
  margin-bottom: 1em;
  transition: background 0.5s;
`;

export const Encyclopedia = () => {
  const [activeSpace, setActiveSpace] = useState({});
  const [errors, setErrors] = useState(null);
  const [loading, setLoading] = useState(true);
  const spaces = useSpaces();
  const dispatch = useSpacesDispatch();

  // const [lists, setLists] = useState({
  //   categories: {},
  //   display: [],
  //   loading: true,
  //   master: [],
  // });
  // const [params, setParams] = useState({
  //   category: "name",
  //   dictionary: true,
  //   order: "asc",
  //   search: "",
  //   activeOnly: false,
  // });
  // const { url } = useContext(environment.context);

  // const sortAndOrder = (list) => {
  //   return orderByKey(
  //     sortArr(list, params.category, params.order),
  //     params.category,
  //     params.dictionary
  //   );
  // };

  // const applyParams = (list = []) => {
  //   return list
  //     .map((item) => {
  //       let [key, values] = Object.entries(item)[0];
  //       values = values.filter((value) => {
  //         if (params.activeOnly === true && value.status === "Inactive") {
  //           return false;
  //         }
  //         // will return false if none of the item's values include the search string
  //         return Object.entries(value).some(([key, val]) => {
  //           if (key === "status") return false;
  //           return val.toLowerCase().includes(params.search);
  //         });
  //       });
  //       if (values.length === 0) return false;
  //       else return { [key]: values };
  //     })
  //     .filter((item) => item);
  // };

  // * on page load
  useEffect(() => {
    (async () => {
      if (spaces.length === 0) {
        try {
          let data = await fetch(
            "https://q9okllrqkf.execute-api.us-east-1.amazonaws.com/api/spaces",
          );
          const { Items: items } = await data.json();
          // flatten obj
          items.forEach((item) => {
            for (const key in item) {
              if (Object.keys(item[key])[0] === "NULL") item[key] = null;
              else item[key] = Object.values(item[key])[0];
            }
          });
          dispatch(items);
          setLoading(false);
        } catch (e) {
          console.error(e);
          setErrors(e);
          setLoading(false);
        }
      } else {
        setLoading(false);
      }
    })();
  });

  const onSpaceTitleClick = (space) => {
    setActiveSpace(space);
  };

  // * on param update
  // useEffect(() => {
  //   let newList;
  //   if (!lists.categories[params.category]) {
  //     newList = sortAndOrder(lists.master);
  //   }
  //   setLists({
  //     ...lists,
  //     display: applyParams(newList || lists.categories[params.category]),
  //     categories: { ...lists.categories, [params.category]: newList },
  //   });
  // }, [params]);

  // const filterChangeHandler = (param, value) => {
  //   const associactedParams = {};
  //   if (param === "category") {
  //     associactedParams.dictionary = value === "name";
  //     associactedParams.order = value === "yearFounded" ? "desc" : "asc";
  //   }
  //   setParams({
  //     ...params,
  //     ...associactedParams,
  //     [param]: value,
  //   });
  // };

  return (
    <Container>
      {/* <Filter
        categories={[
          "Name",
          "City",
          "State/Province",
          "Country",
          "Year Founded",
        ]}
        onChange={filterChangeHandler}
      /> */}
      <Content>
        <ListContainer>
          {loading ? (
            <Spinner />
          ) : errors ? (
            <div>{errors}</div>
          ) : (
            <OrderedList
              items={spaces}
              onClick={onSpaceTitleClick}
              sortBy="name"
            />
          )}
        </ListContainer>
        <DetailContainer>
          <SpaceColor
            color={
              activeSpace.name
                ? convert.stringToColor(activeSpace.name)
                : "#ddd"
            }
          />
          {activeSpace.name && <SpaceDetails space={activeSpace} />}
        </DetailContainer>
      </Content>
    </Container>
  );
};

// const FilterContainer = styled.div`
//   border-bottom: 1px solid #ccc;
//   display: flex;
//   justify-content: center;
//   padding: 1em 2em;
//   > * {
//     margin-right: 1em;
//   }
// `;

// const Filter = ({ categories, onChange }) => {
//   const onChangeHandler = ({ target: { title, value } }) => {
//     if (title === "category") {
//       if (value === "Year Founded") {
//         value = value.replace(" ", "");
//       } else if (value === "State/Province") {
//         value = value.split("/")[0];
//       }
//       value = value[0].toLowerCase() + value.slice(1);
//     }
//     onChange(title, value);
//   };
//   return (
//     <FilterContainer>
//       <Select
//         header="Sort By"
//         onChange={onChangeHandler}
//         options={categories}
//         title="category"
//       />
//       <Text onChange={onChangeHandler} placeholder="Search" title="search" />
//       <Checkbox label="Active" onClick={onChangeHandler} title="activeOnly" />
//     </FilterContainer>
//   );
// };
