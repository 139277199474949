import React from "react";
import { createRoot } from "react-dom/client";
import { createBrowserRouter, RouterProvider } from "react-router-dom";

import { GlobalStyle } from "./styles";
import { Admin, Login } from "./views/admin";
import { About, Client, Encyclopedia, Submit } from "./views/client";
import { Error } from "./views/common";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Client />,
    errorElement: <Error />,
    children: [
      {
        index: true,
        element: <Encyclopedia />,
      },
      {
        path: "about",
        element: <About />,
      },
      {
        path: "submit",
        element: <Submit />,
      },
    ],
  },
  {
    path: "admin",
    element: <Admin />,
    children: [
      {
        path: "login",
        element: <Login />,
      },
    ],
  },
]);

createRoot(document.getElementById("root")).render(
  <>
    <GlobalStyle />
    <RouterProvider router={router} />
  </>,
);
