import { createGlobalStyle } from "styled-components";

export const GlobalStyle = createGlobalStyle`
  body {
    background: #f8f4eb;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
    font: 16px "Hind", sans-serif;
    height: 100vh;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    #root {
      height: inherit;
    }
  }

  * {
    box-sizing: border-box;
  }

  ::-webkit-scrollbar {
    width: 0px;
    background: transparent;
  }

  a {
    color: black;
    &:hover {
      text-decoration: none;
    }
  }

  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
  }

  header, nav {
    a {
      text-decoration: none;
      &:hover {
        text-decoration: underline;
      }
    }
  }

  input {
    border-radius: 0;
  }

  select {
    // remove browser styling
    -webkit-appearance: none;
    -moz-appearance: none;  
  }
`;
