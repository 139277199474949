import PropTypes from "prop-types";
import React from "react";
import { NavLink } from "react-router-dom";
import styled from "styled-components";

const Container = styled.header`
  border-bottom: 1px solid #ccc;
  display: flex;
  padding: 1em;
  > * {
    margin-right: 1em;
    &.active {
      text-decoration: underline;
    }
  }
`;

export const Header = ({ items }) => {
  const status = ({ isActive }) => {
    return isActive ? "active" : "";
  };
  return (
    <Container>
      {items.map(({ title, url }) => (
        <NavLink className={status} key={title} to={url}>
          {title}
        </NavLink>
      ))}
    </Container>
  );
};

Header.propTypes = {
  items: PropTypes.array,
};
