import PropTypes from "prop-types";
import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

const Container = styled.nav`
  border-right: 1px solid #ccc;
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 1em;
  width: fit-content;
`;

export const SideNav = ({ items }) => {
  return (
    <Container>
      {items.map(({ title, url }, i) => (
        <Link key={`link-${i}`} to={url}>
          {title}
        </Link>
      ))}
    </Container>
  );
};

SideNav.propTypes = {
  items: PropTypes.array,
};
